<template>
  <b-container fluid>
    <b-row>
      <!-- Redemption processing Modal -->
      <ProcessRedemption :key="showRedemptionProcessingModal" :propShowRedemptionProcessingModal="showRedemptionProcessingModal" :propRedemptionData="propRedemptionData" @emitCloseRedemptionProcessingModal="emitCloseRedemptionProcessingModal" :propOrgId="orgId" /><!-- Redemption processing Modal -->

      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-8 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchpayredeemWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->

              <!-- Organisation Name Filter -->
              <b-col sm="2" md="2" lg="2" xl="3" class="mb-2">
                <select v-model="whereFilter.org_id" class="form-control" @change="getFilteredData" v-if="organisationObjList && organisationObjList.length > 0" title="Select Organisation">
                  <option :value="null">All</option>
                  <option v-for="(org, index) of organisationObjList" :key="(index+1)" :value="org.org_id">
                    {{org.org_name}}
                  </option>
                </select>
              </b-col><!-- Organisation Name Filter -->

              <!-- Redemption Status Filter -->
              <b-col sm="2" md="2" lg="2" xl="3" class="mb-2">
                <select v-model="whereFilter.redemption_status" class="form-control" @change="getFilteredData" title="Select Redemption Status">
                  <option :value="null">All</option>
                  <option v-for="(status, key) of redemptionStatusStringObj" :key="key" :value="key">
                    {{status}}
                  </option>
                </select>
              </b-col><!-- Redemption Status Filter -->
              <b-col class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <b-btn variant="primary" class="Download_button mr-2 mb-1" @click="backendCsvDownload"> Download</b-btn>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="payredeemObjList && payredeemObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="payredeemObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(commission_percentage_rate)="data">
                    <span>{{ data.item.commission_percentage_rate }}%</span><br>
                    <span>{{ data.item.org_admin_subs_plan_id }}</span>
                  </template>

                  <template v-slot:cell(user_id)="data">
                    <span class="pointer" @click="viewDetails(`/user/${data.item.user_id}`)"> {{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id.substring(0, 25) }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Email" @click="doCopy(data.item.user_email)"></i><br>
                      Mobile:&nbsp;{{data.item.user_mobile}}<br><br>
                    </small></span>
                    <b-badge :style="getStateColorCode()" class="mb-4 mt-2">
                      {{redemptionStatusStringObj[data.item.redemption_status]}}
                    </b-badge><br>
                  </template>

                  <template v-slot:cell(payout_tid_img)="data">
                    <div v-for="(img, index) of data.item.payout_tid_img" :key="index">
                      <img class="pointer pdfLogoImg" v-if="img.url" :src="pdfLogo" @click="openFile(img.url)">
                    </div>
                    <small v-if="data.item.payout_tid">
                      Txn. Id:&nbsp;{{ data.item.payout_tid.substring(0, 10) }}...
                      <i class="fa-solid fa-copy pointer" title="Copy Payout Txn Id" @click="doCopy(data.item.payout_tid)"></i>
                    </small>
                  </template>

                  <template v-slot:cell(invoice)="data">
                    <div v-for="(inv, index) of data.item.invoice" :key="index">
                      <i  v-if="inv.url" class="fa-solid fa-file-invoice fa-lg pointer primary-color" @click="openFile(inv.url)" title="GST Invoice"></i>
                    </div>
                  </template>

                  <template v-slot:cell(pay_tid)="data">
                    <span>{{ data.item.pay_tid }}</span><br>
                    <b-badge :style="getStateColorCode()" class="mb-4 mt-2">
                      {{ data.item.pay_status_code }}
                    </b-badge><br>
                  </template>

                  <template v-slot:cell(org_id)="data">
                    <span>{{ data.item.org_name }}</span><br>
                    <small v-if="data.item.org_id">
                      Id:&nbsp;{{ data.item.org_id.substring(0, 10) }}...
                      <i class="fa-solid fa-copy pointer" title="Copy organisation Id" @click="doCopy(data.item.org_id)"></i>
                    </small><br><br/>
                    <small>Redeem Req Id:&nbsp;{{ data.item.pay_redeem_id.substring(0, 10) }}... <i class="fa-solid fa-copy pointer" title="Copy Redeem Req Id" @click="doCopy(data.item.pay_redeem_id)"></i></small><br/>
                    <small>Redeemption Period:&nbsp;{{ data.item.redeem_for_period }}</small><br/>
                    <small v-if="data.item.payout_tid">Banking Txn:&nbsp;{{ data.item.payout_tid.substring(0, 10) }}...<i class="fa-solid fa-copy pointer" title="Copy Banking Txn Id" @click="doCopy(data.item.payout_tid)"></i></small><br/>
                    <b-badge :style="getStateColorCode()" class="mb-4 mt-2">
                      {{redemptionStatusStringObj[data.item.redemption_status]}}
                    </b-badge><br>
                    <span>
                      <i class="fa-lg primary-color pointer fa-solid fa-sack-dollar" @click="goToOrgRedemptionDashboard(data.item.org_id)" title="Go To Redemption Dashboard"></i>
                      <i v-if="data.item.redemption_status != 'PAID'" class="fa-lg primary-color ml-3 pointer fa-solid fa-money-check-dollar" @click="openProcessRedemptionModal(data.item)" title="Process Redemption"></i>
                      <i class="fa-lg pointer fa-solid fa-maximize fa-eye ml-3 primary-color" title="View Details" @click="viewBreakDown(data.item.org_id)"></i>
                      <i class="ml-3 fa-lg pointer fa-solid primary-color fa-solid fa-images" v-for="(img, index) of data.item.payout_tid_img" :key="index" title="Payment proof" @click="openFile(img.url)"></i>
                    </span>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { socialvue } from "../../../../config/pluginInit.js"
import userPermission from "../../../../Utils/user_permission.js"
import { PayRedemption } from "../../../../FackApi/api/PayRedemption.js"
import pdfLogo from "../../../../assets_gide/img/logo/pdfLogo.png"
import ProcessRedemption from "./ProcessRedemption.vue"
import RedemptionStatusJson from "../../../../FackApi/json/RedemptionStatus.json"

export default {
  name: "OrgSaasRedemptionAdminDashboard",
  components: {
    ProcessRedemption
  },
  data () {
    return {
      apiName: "pay_redemption_list_admin",
      showRedemptionProcessingModal: false,
      propRedemptionData: null,
      showBreakDown: 0,
      cvCardTitle: "GIDEPreneur Finance Dashboard",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit payredeem",
      cvAddModalHeader: "Add payredeem",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "payredeem",
      showModelpayredeemAdd: false,
      showModelpayredeemEdit: false,
      showModelpayredeemDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [],
      payredeemObjList: [],
      payredeemEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        org_id: null,
        redemption_status: "TOBEAPPROVED,TOBEPAID,TOBERECOVERED"
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 100,
      showLoadMoreBtn: true,
      redemptionStatusStringObj: RedemptionStatusJson,
      organisationObjList: null,
      pdfLogo: pdfLogo,
      orgId: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role. Also allow to open this component in Modal Box and not in a URL as URL is only for GIDE Team
    if (this.userData.user_role != "USERROLE11111" && this.userData.user_role != "USERROLE11118") {
      if (!userPermission(this, this.userData, this.apiName)) {
        this.$router.back()
      }
    }
  },
  async mounted () {
    if (this.userData.user_country == 99) {
      this.columns = [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Organisation", key: "org_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "User", key: "user_id", class: "text-left align-text-top", sortable: true },
        { label: "pay tid", key: "pay_tid", class: "text-left align-text-top", sortable: true },
        { label: "invoice", key: "invoice", class: "text-left align-text-top", sortable: true },
        { label: "Revenue INR", key: "revenue_inr", class: "text-left align-text-top", sortable: true },
        { label: "commission INR with GST(18%)", key: "commission_inr_with_gst", class: "text-left align-text-top", sortable: true },
        { label: "commission INR without GST", key: "commission_inr_without_gst", class: "text-left align-text-top", sortable: true },
        { label: "commission percentage rate", key: "commission_percentage_rate", class: "text-left align-text-top", sortable: true }
      ]
    }
    else {
      this.columns = [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Organisation", key: "org_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "pay tid", key: "pay_tid", class: "text-left align-text-top", sortable: true },
        { label: "pay tid", key: "pay_tid", class: "text-left align-text-top", sortable: true },
        { label: "invoice", key: "invoice", class: "text-left align-text-top", sortable: true },
        { label: "Revenue USD", key: "revenue_usd", class: "text-left align-text-top", sortable: true },
        { label: "commission USD with GST(18%)", key: "commission_usd_with_gst", class: "text-left align-text-top", sortable: true },
        { label: "commission USD without GST", key: "commission_usd_without_gst", class: "text-left align-text-top", sortable: true },
        { label: "GIDEPreneur Subscription", key: "commission_percentage_rate", class: "text-left align-text-top", sortable: true }
      ]
    }

    // these parameters are used to display breakdown of the redemptions
    if (this.$route.query.org_id) {
      this.showBreakDown = 1
      this.whereFilter.org_id = this.$route.query.org_id
    }

    if (this.$route.query.redemption_status) {
      this.whereFilter.redemption_status = this.$route.query.redemption_status
    }

    if (this.showBreakDown == 0) {
      this.columns = this.columns.filter(e => e.key != "pay_tid" && e.key != "user_id")
    }
    else {
      this.columns = this.columns.filter(e => {
        return (
          e.key != "org_id" && e.key != "invoice" && e.key != "payout_tid_img"
        )
      })
    }
    // these parameters are used to display breakdown of the redemptions

    socialvue.index()
    this.payRedemptionListAdmin()
    this.getDashboardFilters()
  },
  methods: {
    /**
     * searchpayredeemWithParams
     */
    async searchpayredeemWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.payRedemptionListAdmin()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.payRedemptionListAdmin(true)
    },
    /**
     * payRedemptionListAdmin
     */
    async payRedemptionListAdmin (loadViaLoadMore = false, downloadCsv = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.show_breakdown = this.showBreakDown
        this.whereFilter.downloadCsv = downloadCsv

        let payredeemListResp = await PayRedemption.payRedemptionListAdmin(this, this.whereFilter)
        if (payredeemListResp.resp_status) {
          if (downloadCsv) {
            window.open(payredeemListResp.resp_csv_file_url)
            return
          }

          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.payredeemObjList = [ ...payredeemListResp.resp_data.data ]
          }
          else {
            this.payredeemObjList = [ ...this.payredeemObjList, ...payredeemListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.payredeemObjList.forEach((s, index) => {
            s.id = index + 1
            s.payout_tid_img = JSON.parse(s.payout_tid_img)
            s.invoice = JSON.parse(s.invoice)
          })
          this.totalRows = this.payredeemObjList.length
        }
        else {
          this.currentBatchNo--
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = payredeemListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at payRedemptionListAdmin() and Exception:", err)
      }
    },
    /**
     * getDashboardFilters
     */
    async getDashboardFilters () {
      try {
        const redemptionFiltersResp = await PayRedemption.payRedemptionFilterGet(this)
        if (redemptionFiltersResp.resp_status) {
          this.organisationObjList = redemptionFiltersResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception in getDashboardFilters() and err: ", err.message)
      }
    },
    /**
     * showpayredeemDeleteDialog
     */
    showpayredeemDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelpayredeemDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showpayredeemDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * getStateColorCode
     */
    getStateColorCode () {
      // `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
    },
    /**
     * getFilteredData
     */
    getFilteredData () {
      this.currentBatchNo = 1
      this.payRedemptionListAdmin()
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * openFile
     */
    openFile (url) {
      if (!url) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Invalid file url"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * goToOrgRedemptionDashboard
     */
    goToOrgRedemptionDashboard (orgId) {
      window.open(`/redemption_dashboard?org_id=${orgId}`, "_blank")
    },
    /**
     * viewBreakDown
     */
    async viewBreakDown (orgId) {
      try {
        window.open(`/redemption_admin_dashboard?org_id=${orgId}&redemption_status=${this.whereFilter.redemption_status}`)
      }
      catch (err) {
        console.error("Exception in viewBreakDown() and err: ", err.message)
      }
    },
    /**
     * viewDetails
     */
    viewDetails (path) {
      window.open(path, "_blank")
    },
    /**
     * openProcessRedemptionModal
     */
    openProcessRedemptionModal (item) {
      this.orgId = item.org_id
      this.propRedemptionData = {
        redemption_period: item.redeem_for_period,
        ...item
      }
      this.showRedemptionProcessingModal = true
    },
    /**
     * emitCloseRedemptionProcessingModal
     */
    emitCloseRedemptionProcessingModal () {
      this.payRedemptionListAdmin()
      this.showRedemptionProcessingModal = false
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.payRedemptionListAdmin(false, true)
    }
  }
}
</script>
<style>
  .pdfLogoImg {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border: 2px solid aliceblue;
    margin-bottom: 6px;
  }
</style>
