<template lang="">
  <div>
    <b-modal size="xl" v-model="showRedemptionProcessingModal" :title="'Process Payout'" no-close-on-backdrop no-close-on-esc @hide="closeRedemptionProcessingModal">
      <div>
        <b-row>
          <b-col class="col-10">
            <b>Total Payable amount: </b>
          </b-col>
          <b-col class="col-2">
            <input v-model="redemeedAmt" type="number" class="form-control"/>
          </b-col>
        </b-row>
        <span v-if="redemeedAmt < 0"><b>Alert:</b>&nbsp; We are running short of <span class="primary-color">{{redemeedAmt}}</span> and we need to collect it from the GIDEPreneur</span>
      </div>

      <!-- Proof of payment of commission -->
      <b-row class="mt-2">
        <div class="col-12 mb-2">
          <label for="validationslot_acHolderName">Banking Transaction ID</label>&nbsp;<label class="primary-color font-size-16">*</label>
          <ValidationProvider name="Banking Transaction ID" rules="required|min:2" v-slot="{ errors }">
            <input v-model="vmPaymentProofFormData.payout_tid" type="text" class="form-control" required/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!-- Upload Payment Screenshot -->
        <div class="col-md-12">
          <label>{{ paymentProofImgLabel }} : {{ imageSize }} <span class="primary-color font-size-16">*</span></label>
          <div class="form-group row align-items-center ml-1">
            <div class="profile-img-edit border" style="height: 200px; width: 300px;">
              <img v-if="vmPaymentProofImg" :src="vmPaymentProofImg" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
              <i v-else class="fas fa-image d-flex justify-content-center align-items-center pointer" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
              <label class="p-image">
                <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                <input class="file-upload" type="file" required accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event)" />
              </label>
            </div>
            <span class="text-danger" ref="coverPicError"></span>
          </div>
        </div><!-- Upload Payment Screenshot -->

      </b-row><!-- Proof of payment of commission -->

      <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

      <p>
        <button type="button" class="btn btn-primary ml-1 mt-1" @click="savePaymentProof()">Save Payment Proof</button>
      </p>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" variant="secondary" @click="closeRedemptionProcessingModal()">
          {{propCancelBtnText}}
        </b-button>
      </template>
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<script>
import CropImage from "../../../../components/cropImage.vue"
import { PayRedemption } from "../../../../FackApi/api/PayRedemption"
import ApiResponse from "../../../../Utils/apiResponse"

export default {
  name: "ProcessRedemption",
  mounted () {
    this.redemptiondata = this.propRedemptionData
    this.showRedemptionProcessingModal = this.propShowRedemptionProcessingModal
    this.getRedemptionParticulars()
  },
  props: {
    propShowRedemptionProcessingModal: {
      type: Boolean,
      default: false
    },
    propRedemptionData: {
      type: Object,
      default: null
    },
    propOrgId: {
      type: String,
      default: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      redemeedAmt: 0.00,
      fileSrc: "",
      propCropDialogSize: "md",
      imageSize: "Size  1080 * 566",
      paymentProofImgLabel: "Payment Screenshot",
      propCropImageDialogOpen: false,
      propStencilSize: null,
      vmPaymentProofImg: null,
      vmPaymentProofFormData: {
        payout_tid_img: []
      },
      propCancelBtnText: "Cancel",
      redemptiondata: null,
      showRedemptionProcessingModal: false,
      creditStates: ["TOBEPAID", "TOBEAPPROVED"],
      debitStates: ["TOBEDEDUCTED"],
      processedStates: ["DEDUCTED", "PAID"],
      toastTitle: "Process redemption",
      showToast: false,
      toastVariant: "default",
      toastMsg: ""
    }
  },
  components: {
    CropImage
  },
  methods: {
    /**
     * closeRedemptionProcessingModal
     */
    closeRedemptionProcessingModal () {
      this.$emit("emitCloseRedemptionProcessingModal")
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      this.vmPaymentProofImg = img
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e) {
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024

        if (!allowedExtension.includes(image.type)) {
          this.toastMsg = `Screenshot of payment proof should have one of the following extensions ${allowedExtension.join(", ")}`
          this.showToast = true
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          this.toastMsg = `Screenshot should be of size: ${maxSize}`
          this.showToast = true
          return false
        }

        this.propStencilSize = {
          "width": 540,
          "height": 283
        }

        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },

    /**
     * getRedemptionParticulars
     */
    async getRedemptionParticulars () {
      try {
        if (this.processedStates.includes(this.redemptiondata.redemption_status)) {
          // Opens the redemption processing modal for view/edit payment proof
          this.vmPaymentProofFormData = {
            pay_redeem_id: this.redemptiondata.pay_redeem_id,
            payout_tid: this.redemptiondata.payout_tid
          }
          this.vmPaymentProofImg = JSON.parse(this.redemptiondata.payout_tid_img)[0].url
          return
        }

        const payload = {
          org_id: this.propOrgId,
          org_admin_email: this.redemptiondata.org_admin_email,
          redemption_period: this.redemptiondata.redemption_period
        }

        let redemptionParticulars = await PayRedemption.payRedemptionGetParticulars(this, payload)
        if (!redemptionParticulars.resp_status) {
          ApiResponse.responseMessageDisplay(this, redemptionParticulars)
          return
        }
        this.redemeedAmt = this.userData.user_country == 99 ? redemptionParticulars.resp_data.final_amt_inr : redemptionParticulars.resp_data.final_amt_usd
      }
      catch (err) {
        console.error("Exception in getRedemptionParticulars() and err: ", err.message)
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      this.vmPaymentProofFormData.payout_tid_img.push(imgObj.orgImage)
    },
    /**
     * savePaymentProof
    */
    async savePaymentProof () {
      try {
        if (this.vmPaymentProofFormData.payout_tid_img && this.vmPaymentProofFormData.payout_tid_img.length == 0) {
          this.toastMsg = "Please upload a screenshot as a payment proof"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        if (!this.vmPaymentProofFormData.payout_tid) {
          this.toastMsg = "Please provide the banking transaction id"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        // append the pay_redeem_id and the org_id and user_country
        this.vmPaymentProofFormData.pay_redeem_id = this.redemptiondata.pay_redeem_id
        this.vmPaymentProofFormData.module_obj_id = this.redemptiondata.org_id
        this.vmPaymentProofFormData.user_country = this.userData.user_country

        // append the commission and other details so that we can send details like redemption_period, commission_amt etc in the email to approved team
        this.vmPaymentProofFormData.commission_inr_with_gst = this.redemptiondata.commission_inr_with_gst
        this.vmPaymentProofFormData.commission_usd_with_gst = this.redemptiondata.commission_usd_with_gst

        this.vmPaymentProofFormData.commission_inr_without_gst = this.redemptiondata.commission_inr_without_gst
        this.vmPaymentProofFormData.commission_usd_without_gst = this.redemptiondata.commission_usd_without_gst

        this.vmPaymentProofFormData.redemption_period = this.redemptiondata.redemption_period
        this.vmPaymentProofFormData.payout_amount = this.redemeedAmt

        const paymentProofEditResp = await PayRedemption.payredeemEdit(this, this.vmPaymentProofFormData)
        if (!paymentProofEditResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, paymentProofEditResp)
          return
        }
        this.closeRedemptionProcessingModal()
      }
      catch (err) {
        console.error("Exception in savePaymentProof() and err: ", err.message)
      }
    }
  }
}
</script>
<style lang="">
</style>
